import React, { useState, useEffect, useCallback } from 'react';
import { handleTranslation } from '../services/translationService';
import { TranslationResult, TranslationSettings } from '../types';
import { SettingsPanel } from './SettingsPanel';
import debounce from 'lodash/debounce';
import { Button } from "./ui/button";
import { Textarea } from "./ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Card, CardContent } from "./ui/card";
import { ThemeToggle } from "./ui/theme-toggle";
import { Copy, Loader2, ChevronDown, ChevronUp } from "lucide-react";

export const TranslatorPanel: React.FC = () => {
  const [text, setText] = useState('');
  const [sourceLang, setSourceLang] = useState('auto');
  const [targetLang, setTargetLang] = useState('zh');
  const [result, setResult] = useState<TranslationResult | null>(null);
  const [loading, setLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [settings, setSettings] = useState<TranslationSettings>(() => {
    const savedSettings = localStorage.getItem('translationSettings');
    return savedSettings ? JSON.parse(savedSettings) : {
      apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://api.openai.com',
      apiKey: process.env.REACT_APP_API_KEY || '',
      model: 'gpt-3.5-turbo',
      firstPrompt: 'Translate from {source_lang} to {target_lang}: {source_text}',
      reflectionPrompt: 'Analyze this translation: {translation_1}',
      secondPrompt: 'Improve the translation based on the analysis: {reflection}'
    };
  });
  const [isReflectionExpanded, setIsReflectionExpanded] = useState(false);

  useEffect(() => {
    localStorage.setItem('translationSettings', JSON.stringify(settings));
  }, [settings]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!text) return;

    setLoading(true);
    try {
      const translationResult = await handleTranslation(
        text,
        sourceLang,
        targetLang,
        settings
      );
      setResult(translationResult);
    } catch (error) {
      console.error('Translation failed:', error);
      alert('翻译失败: ' + (error instanceof Error ? error.message : '未知错误'));
    } finally {
      setLoading(false);
    }
  };

  const handleSettingsSave = (newSettings: TranslationSettings) => {
    setSettings(newSettings);
    setShowSettings(false);
  };

  const debouncedSetText = useCallback(
    debounce((value: string) => {
      setText(value);
    }, 300),
    []
  );

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    debouncedSetText(e.target.value);
  };

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  return (
    <div className="min-h-screen pb-8">
      <Card className="max-w-4xl mx-auto mt-8 p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">🌐 Fu Translator</h2>
          <div className="flex items-center gap-2">
            <ThemeToggle />
            <Button
              variant="outline"
              onClick={() => setShowSettings(!showSettings)}
            >
              ⚙️ 设置
            </Button>
          </div>
        </div>

        {showSettings ? (
          <SettingsPanel settings={settings} onSave={handleSettingsSave} />
        ) : (
          <>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-2">
                <Textarea
                  onChange={handleTextareaChange}
                  placeholder="请输入要翻译的文本"
                  className="min-h-[150px]"
                  defaultValue={text}
                />
              </div>
              
              <div className="flex items-center gap-4">
                <Select
                  value={sourceLang}
                  onValueChange={setSourceLang}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="选择源语言" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="auto">自动检测</SelectItem>
                    <SelectItem value="en">英语</SelectItem>
                    <SelectItem value="zh">中文</SelectItem>
                    <SelectItem value="ja">日语</SelectItem>
                    <SelectItem value="ko">韩语</SelectItem>
                    <SelectItem value="fr">法语</SelectItem>
                    <SelectItem value="de">德语</SelectItem>
                  </SelectContent>
                </Select>

                <div className="flex-shrink-0">➡️</div>

                <Select
                  value={targetLang}
                  onValueChange={setTargetLang}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="选择目标语言" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="zh">中文</SelectItem>
                    <SelectItem value="en">英语</SelectItem>
                    <SelectItem value="ja">日语</SelectItem>
                    <SelectItem value="ko">韩语</SelectItem>
                    <SelectItem value="fr">法语</SelectItem>
                    <SelectItem value="de">德语</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <Button 
                type="submit" 
                disabled={loading}
                className="w-full"
              >
                {loading ? '翻译中...' : '翻译'}
              </Button>
            </form>

            {loading ? (
              <div className="mt-6 space-y-4">
                <Card>
                  <CardContent className="p-4">
                    <div className="flex items-center space-x-4">
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <div className="space-y-2">
                        <div className="h-4 w-[250px] animate-pulse rounded bg-muted"></div>
                        <div className="h-4 w-[200px] animate-pulse rounded bg-muted"></div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                
                <Card>
                  <CardContent className="p-4">
                    <div className="flex items-center space-x-4">
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <div className="space-y-2">
                        <div className="h-4 w-[300px] animate-pulse rounded bg-muted"></div>
                        <div className="h-4 w-[250px] animate-pulse rounded bg-muted"></div>
                        <div className="h-4 w-[200px] animate-pulse rounded bg-muted"></div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                
                <Card>
                  <CardContent className="p-4">
                    <div className="flex items-center space-x-4">
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <div className="space-y-2">
                        <div className="h-4 w-[280px] animate-pulse rounded bg-muted"></div>
                        <div className="h-4 w-[220px] animate-pulse rounded bg-muted"></div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            ) : (
              result && (
                <div className="mt-6 space-y-6">
                  <Card className="overflow-hidden border-2 border-muted/20">
                    <CardContent className="p-6">
                      <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center gap-2">
                          <div className="h-8 w-8 rounded-full bg-blue-100 dark:bg-blue-900/30 flex items-center justify-center">
                            <span className="text-blue-600 dark:text-blue-400">1</span>
                          </div>
                          <h3 className="text-sm font-medium text-muted-foreground">初次翻译</h3>
                        </div>
                      </div>
                      <div className="text-base leading-relaxed">{result.first}</div>
                    </CardContent>
                  </Card>
                  
                  <Card className="overflow-hidden border-2 border-muted/20">
                    <CardContent className="p-6">
                      <div 
                        className="flex justify-between items-center mb-4 cursor-pointer"
                        onClick={() => setIsReflectionExpanded(!isReflectionExpanded)}
                      >
                        <div className="flex items-center gap-2">
                          <div className="h-8 w-8 rounded-full bg-amber-100 dark:bg-amber-900/30 flex items-center justify-center">
                            <span className="text-amber-600 dark:text-amber-400">2</span>
                          </div>
                          <h3 className="text-sm font-medium text-muted-foreground">翻译分析</h3>
                        </div>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-8 w-8 hover:bg-muted/30"
                        >
                          {isReflectionExpanded ? (
                            <ChevronUp className="h-4 w-4" />
                          ) : (
                            <ChevronDown className="h-4 w-4" />
                          )}
                        </Button>
                      </div>
                      {isReflectionExpanded && (
                        <div className="space-y-2 text-sm text-muted-foreground bg-muted/5 rounded-lg p-4 transition-all duration-200">
                          {result.reflection.split('\n').map((line, index) => (
                            line.trim() && (
                              <div key={index} className="flex items-start gap-2">
                                <span className="text-muted-foreground/60">•</span>
                                <span>{line}</span>
                              </div>
                            )
                          ))}
                        </div>
                      )}
                    </CardContent>
                  </Card>
                  
                  <Card className="overflow-hidden border-2 border-muted/20">
                    <CardContent className="p-6">
                      <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center gap-2">
                          <div className="h-8 w-8 rounded-full bg-green-100 dark:bg-green-900/30 flex items-center justify-center">
                            <span className="text-green-600 dark:text-green-400">3</span>
                          </div>
                          <h3 className="text-sm font-medium text-muted-foreground">最终翻译</h3>
                        </div>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleCopy(result.second)}
                          className="h-8 w-8 hover:bg-muted/30"
                          title="复制到剪贴板"
                        >
                          <Copy className="h-4 w-4" />
                        </Button>
                      </div>
                      <div className="text-base leading-relaxed font-medium">{result.second}</div>
                    </CardContent>
                  </Card>
                </div>
              )
            )}
          </>
        )}
      </Card>
      
      <div className="max-w-4xl mx-auto mt-4 text-center text-sm text-muted-foreground">
        <p>制作人 WeChat: hbykdx</p>
      </div>
    </div>
  );
}; 
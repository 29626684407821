import React from 'react';
import { TranslationSettings } from '../types';
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { Label } from "./ui/label";
import { Card, CardContent } from "./ui/card";

interface SettingsPanelProps {
  settings: TranslationSettings;
  onSave: (settings: TranslationSettings) => void;
}

export const SettingsPanel: React.FC<SettingsPanelProps> = ({ settings, onSave }) => {
  const [formData, setFormData] = React.useState(settings);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  const handleReset = () => {
    setFormData({
      apiBaseUrl: 'https://api.openai.com',
      apiKey: '',
      model: 'gpt-3.5-turbo',
      firstPrompt: 'You are an expert linguist, specializing in translation from {source_lang} to {target_lang}. ' +
        'Translate this text accurately and naturally.\n\n' +
        '{source_text}',
      reflectionPrompt: 'When writing suggestions, pay attention to whether there are ways to improve the translation\'s \n' +
        '(i) accuracy (by correcting errors of addition, mistranslation, omission, or untranslated text),\n' +
        '(ii) fluency (by applying {target_lang} grammar, spelling and punctuation rules, and ensuring there are no unnecessary repetitions),\n' +
        '(iii) style (by ensuring the translations reflect the style of the source text and take into account any cultural context),\n' +
        '(iv) terminology (by ensuring terminology use is consistent and reflects the source text domain; and by only ensuring you use equivalent idioms {target_lang}).\n\n' +
        'Write a list of specific, helpful and constructive suggestions for improving the translation.\n' +
        'Each suggestion should address one specific part of the translation.\n' +
        'Output only the suggestions and nothing else.\n\n' +
        'Source: {source_text}\n' +
        'Translation: {translation_1}',
      secondPrompt: 'Provide the improved translation incorporating all suggested changes. ' +
        'Output only the translation.\n\n' +
        'Original: {translation_1}\n' +
        'Changes: {reflection}'
    });
  };

  const handleTestConnection = async () => {
    try {
      const response = await fetch(`${formData.apiBaseUrl}/v1/chat/completions`, {
        method: 'OPTIONS',  // 先尝试 OPTIONS 请求
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${formData.apiKey}`
        }
      });
      
      if (response.ok) {
        alert('API 连接测试成功！');
      } else {
        alert(`API 连接测试失败: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      alert('API 连接测试失败: ' + (error instanceof Error ? error.message : '未知错误'));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof TranslationSettings) => {
    setFormData({...formData, [field]: e.target.value});
  };

  return (
    <Card>
      <CardContent className="p-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">🚀 API 设置</h3>
            
            <div className="space-y-2">
              <Label htmlFor="apiBaseUrl">API Base URL</Label>
              <Input
                id="apiBaseUrl"
                value={formData.apiBaseUrl}
                onChange={(e) => handleInputChange(e, 'apiBaseUrl')}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="apiKey">API Key</Label>
              <Input
                id="apiKey"
                type="password"
                value={formData.apiKey}
                onChange={(e) => handleInputChange(e, 'apiKey')}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="model">Model</Label>
              <Input
                id="model"
                value={formData.model}
                onChange={(e) => handleInputChange(e, 'model')}
              />
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">📚 提示词设置</h3>
            
            <div className="space-y-2">
              <Label htmlFor="firstPrompt">First Translation Prompt</Label>
              <Textarea
                id="firstPrompt"
                value={formData.firstPrompt}
                onChange={(e) => handleInputChange(e, 'firstPrompt')}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="reflectionPrompt">Reflection Prompt</Label>
              <Textarea
                id="reflectionPrompt"
                value={formData.reflectionPrompt}
                onChange={(e) => handleInputChange(e, 'reflectionPrompt')}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="secondPrompt">Second Translation Prompt</Label>
              <Textarea
                id="secondPrompt"
                value={formData.secondPrompt}
                onChange={(e) => handleInputChange(e, 'secondPrompt')}
              />
            </div>
          </div>

          <div className="flex gap-2">
            <Button type="submit">保存设置</Button>
            <Button type="button" variant="outline" onClick={handleReset}>
              重置
            </Button>
            <Button type="button" variant="secondary" onClick={handleTestConnection}>
              测试连接
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}; 
import { TranslationResult, TranslationSettings } from '../types';

// 添加缓存对象
const translationCache = new Map<string, TranslationResult>();

async function getCompletion(
  settings: TranslationSettings,
  prompt: string,
  variables: Record<string, string>
): Promise<string> {
  if (!settings.apiKey) {
    throw new Error('请先设置 API Key');
  }

  // 替换提示词中的变量
  const fullPrompt = prompt.replace(/{(\w+)}/g, (match, key) => {
    return variables.hasOwnProperty(key) ? variables[key] : match;
  });

  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 30000);

  try {
    const response = await fetch(`${settings.apiBaseUrl}/v1/chat/completions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${settings.apiKey}`
      },
      body: JSON.stringify({
        model: settings.model,
        messages: [
          { 
            role: 'system', 
            content: `You are an expert linguist and professional translator, specializing in translation from ${variables.source_lang} to ${variables.target_lang}. 
Your translations should be accurate, natural, and maintain the original style and tone.` 
          },
          { 
            role: 'user', 
            content: fullPrompt 
          }
        ],
        temperature: 0.3,
        top_p: 1
      }),
      signal: controller.signal
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      throw new Error(`API 请求失败: ${response.status}`);
    }

    const data = await response.json();
    return data.choices[0].message.content.trim();
  } catch (error) {
    clearTimeout(timeoutId);
    throw error;
  }
}

export async function handleTranslation(
  text: string, 
  source: string, 
  target: string,
  settings: TranslationSettings
): Promise<TranslationResult> {
  const cacheKey = `${text}-${source}-${target}-${settings.model}`;
  
  if (translationCache.has(cacheKey)) {
    return translationCache.get(cacheKey)!;
  }

  try {
    // 第一次翻译 - 直接翻译
    const firstPrompt = `Translate this text from ${source} to ${target}. 
Maintain the original style and tone. Output only the translation:

<SOURCE>
${text}
</SOURCE>`;

    const firstOutput = await getCompletion(settings, firstPrompt, { 
      source_text: text, 
      source_lang: source, 
      target_lang: target 
    });

    // 分析翻译 - 详细分析并提供具体改进建议
    const reflectionPrompt = `Analyze this translation and provide specific suggestions for improvement:

Source Text (${source}):
${text}

Initial Translation (${target}):
${firstOutput}

Focus on:
1. Accuracy - identify any mistranslations, omissions, or additions
2. Fluency - evaluate grammar, word choice, and natural expression
3. Style - assess if the translation maintains the original tone and register
4. Cultural context - check for cultural appropriateness and idioms

Provide numbered, specific suggestions for improvement.`;

    const reflectionOutput = await getCompletion(settings, reflectionPrompt, { 
      source_text: text, 
      source_lang: source, 
      target_lang: target, 
      translation_1: firstOutput 
    });

    // 最终优化翻译 - 基于分析改进
    const secondPrompt = `Provide an improved translation incorporating all the suggested changes.
Remember to maintain consistency in terminology and style.

Source Text (${source}):
${text}

Initial Translation:
${firstOutput}

Suggested Improvements:
${reflectionOutput}

Output only the final improved translation in ${target}:`;

    const secondOutput = await getCompletion(settings, secondPrompt, { 
      source_text: text, 
      source_lang: source, 
      target_lang: target, 
      translation_1: firstOutput,
      reflection: reflectionOutput 
    });

    const result = {
      first: firstOutput,
      reflection: reflectionOutput,
      second: secondOutput
    };
    
    translationCache.set(cacheKey, result);
    return result;

  } catch (error) {
    console.error('Translation error:', error);
    throw new Error(getErrorMessage(error));
  }
}

function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }
  return '发生未知错误';
} 